















import { ChecktreeItemUpdateMode } from '@/app/shared/enums/checktree-item-update-mode.enum';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

export interface ColorSettingsChangeEvent {
  value: string;
  updateMode: ChecktreeItemUpdateMode;
}

@Component({
  components: {},
})
export default class ColorSettings extends Vue {
  @Prop() initialValue!: string;
  // @Prop() initialUpdateMode!: ChecktreeItemUpdateMode;
  @Prop() selectOptions!: Array<{ label: string; value: ChecktreeItemUpdateMode }>;

  value: string = null;
  updateMode: ChecktreeItemUpdateMode = ChecktreeItemUpdateMode.ONLY_HERE;

  created() {
    this.value = this.initialValue;
    // this.updateMode = this.initialUpdateMode;
  }

  @Emit('change')
  onChange(_event: ColorSettingsChangeEvent) {
    // just emiting value
  }

  @Watch('initialValue')
  onInitialValueChange() {
    this.value = this.initialValue;
  }

  @Watch('updateMode')
  onUpdateModeChange() {
    if ((this.value || this.value === '') && this.updateMode) {
      this.onChange({ value: this.value, updateMode: this.updateMode });
    }
  }

  onValueInput(value: string) {
    this.value = value;
    if ((this.value || this.value === '') && this.updateMode) {
      this.onChange({ value: this.value, updateMode: this.updateMode });
    }
  }

  clearValue() {
    // this.value = '';
    this.onChange(null);
  }
}
