






























import { Component, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { Tree, Draggable, Check, Fold } from 'he-tree-vue';
import { namespace } from 'vuex-class';
import CheckItem from './components/CheckItem.vue';
import ChecktreePageHeader from './components/ChecktreePageHeader.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import api from '@/app/shared/api';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { CheckTree } from '@/app/shared/models/CheckTree';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { WSMessageResponse } from '@/app/shared/models/WSMessageResponse';
import { WebSocketMessageStatus } from '@/app/shared/enums/WebsocketMessageStatus.enum';
import { WSMessageCommand } from '@/app/shared/enums/ws-message-command.enum';
import { TemplateStage, TemplateType } from '@/app/shared/models/Template';
import { ModuleProperties } from '@/app/shared/models/Module';
import DialogService from '@/app/shared/utils/dialog.service';

const ChecklistsStore = namespace('Checklists');
const LoaderStore = namespace('Loader');
const ModulePropertiesStore = namespace('ModuleProperties');
const UserStore = namespace('User');

@Component({
  components: {
    CheckItem,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Tree: (Tree as any).mixPlugins([Draggable, Check, Fold]),
    PageFooter,
    ChecktreePageHeader,
  },
})
export default class CheckTreeView extends Vue {
  @Prop() adminPermission: string;
  unitUuid: string;
  unitListId: number;

  @ChecklistsStore.Getter
  checktree: CheckTree;

  @ChecklistsStore.Getter
  checklistsTreeData: CheckTreeItem[];

  @LoaderStore.Getter
  public isLoaderVisible: boolean;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @ChecklistsStore.Action
  public initializeChecklistsData!: (checktreeUuid: string) => Promise<boolean>;

  @ChecklistsStore.Action
  public onCheckTreeReorder!: (payload: {
    dragNode: CheckTreeItem;
    afterNode: CheckTreeItem;
    isFirstChild: boolean;
    updatedTreeData: CheckTreeItem[];
  }) => Promise<boolean>;

  @ChecklistsStore.Action
  public handleWebsocketMessage!: (response: WSMessageResponse) => Promise<boolean>;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isAdmin() {
    if (!this.adminPermission) return false;
    return await this.isAllowed(this.adminPermission);
  }

  async created() {
    if (this.$route.query.unitListId && this.$route.query.unitUuid) {
      this.unitListId = Number(this.$route.query.unitListId);
      this.unitUuid = this.$route.query.unitUuid as string;
    }
    this.initializeChecklistsData(this.$route.params.uuid);
    // listen for ws messages
    eventBus.$on(EventType.CHECKTREE_RECEIVE_MSG, async (response: WSMessageResponse) => {
      try {
        if (response.status == WebSocketMessageStatus.SUCCESS) {
          const isActionVisibleOnUI: boolean = await this.handleWebsocketMessage(response);
          if (!isActionVisibleOnUI) {
            throw new Error('Action successful, but not visible on UI.');
          }
        } else {
          if (
            (response.command === WSMessageCommand.LOCK && response.lock) ||
            (response.command === WSMessageCommand.UNLOCK && !response.lock)
          ) {
            // if item is already locked/unlocked
            return;
          }
          throw new Error('Action unsuccessful -> error message: ' + response.message);
        }
      } catch (error) {
        console.error(error);
        // TODO: Handle error, show modal
      }
    });
  }

  get checktreeName() {
    return this.moduleProperties ? this.moduleProperties?.name || this.checktree?.name || '' : '';
  }

  isItemExpanded(node: CheckTreeItem): boolean {
    if (node.children && node.children.length > 0) {
      for (const childNode of node.children) {
        if (!this.isItemExpanded(childNode)) {
          return false;
        }
        return !node.$folded;
      }
    } else {
      return true;
    }
  }

  get isAllExpanded() {
    for (const firstLevelNode of this.checklistsTreeData) {
      if (!this.isItemExpanded(firstLevelNode)) {
        return false;
      }
    }
    return true;
  }

  onChange(event: { dragNode: CheckTreeItem; startPath: number[]; targetPath: number[] }) {
    const tree: Tree = this.$refs.treeEl as Tree;

    let nodeBefore: CheckTreeItem = null;
    let isFirstChild = false;

    const childPosition = event.targetPath[event.targetPath.length - 1];

    if (childPosition === 0) {
      // if node position should be first child of some node
      isFirstChild = true;
      if (event.targetPath.length > 1) {
        // node is not on first level
        nodeBefore = tree.getNodeParentByPath(event.targetPath) as CheckTreeItem;
      }
    } else {
      // if node is at second or higher position in children array
      const childBeforePath: number[] = [...event.targetPath];
      const startNumber = Number(event.startPath.join(''));
      const targetNumber = Number(event.targetPath.join(''));

      if (targetNumber < startNumber) {
        childBeforePath[childBeforePath.length - 1] = childPosition - 1;
      } else {
        childBeforePath[childBeforePath.length - 1] = childPosition - 2;
      }

      nodeBefore = tree.getNodeByPath(childBeforePath) as CheckTreeItem;
    }

    const updatedTreeData = tree.cloneTreeData() as CheckTreeItem[];
    this.onCheckTreeReorder({
      dragNode: event.dragNode,
      afterNode: nodeBefore,
      isFirstChild,
      updatedTreeData,
    });
  }

  async onResume() {
    try {
      // Handle template specific checktree resume action.
      if (
        this.$route.params.uuid &&
        this.$route.params.workspaceItemUuid &&
        this.$route.params.stage &&
        this.$route.params.templateType
      ) {
        const confirmCallback = async () => {
          await api.templates.resumeTemplate(
            this.$route.params.uuid,
            this.$route.params.workspaceItemUuid,
            this.$route.params.stage as TemplateStage,
            this.$route.params.templateType as TemplateType,
          );
          this.$router.go(-2);
        };

        DialogService.presentDialog(
          'Are you sure?',
          'First level titles in a report template (table of content) are chapters, which will now be created, while later cannot be edited (title change), new added or existing deleted, after your confirmation.<br /><br />However, lower level titles are headings and subheadings, and can be altered in a report, at any time.<br /><br />Note: please make sure you have checked all items / titles that are relevant for your report (check button on the right).',
          'Yes',
          'Cancel',
          confirmCallback,
        );

        return;
      }

      // Checktree resume.
      if (!this.unitUuid) {
        await api.checktree.resume(this.$route.params.uuid).then(() => {
          this.$router.go(-1);
        });
        return;
      }
    } catch (error) {
      console.warn(error);
      return;
    }

    // By default just go back.
    this.$router.go(-1);
    return;
  }
}
