














import { ChecktreeItemUpdateMode } from '@/app/shared/enums/checktree-item-update-mode.enum';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

export interface CommentPlaceholderChangeEvent {
  value: string;
  updateMode: ChecktreeItemUpdateMode;
}

@Component({
  components: {},
})
export default class CommentPlaceholder extends Vue {
  @Prop() initialValue!: string;
  // @Prop() initialUpdateMode!: ChecktreeItemUpdateMode;
  @Prop() selectOptions!: Array<{ label: string; value: ChecktreeItemUpdateMode }>;

  value: string = null;
  updateMode: ChecktreeItemUpdateMode = ChecktreeItemUpdateMode.ONLY_HERE;

  created() {
    this.value = this.initialValue;
    // this.updateMode = this.initialUpdateMode;
  }

  @Emit('change')
  onChange(_event: CommentPlaceholderChangeEvent) {
    // just emiting value
  }

  @Emit('delete')
  onDelete() {
    // just emiting value
  }

  @Watch('initialValue')
  onInitialValueChange() {
    this.value = this.initialValue;
  }

  @Watch('value')
  onValueChange(newVal: string, _oldVal: string) {
    if ((this.value || this.value === '') && this.updateMode && newVal !== this.initialValue) {
      this.onChange({ value: this.value, updateMode: this.updateMode });
    }
  }

  @Watch('updateMode')
  onUpdateModeChange(_oldVal: ChecktreeItemUpdateMode, _newVal: ChecktreeItemUpdateMode) {
    if ((this.value || this.value === '') && this.updateMode) {
      this.onChange({ value: this.value, updateMode: this.updateMode });
    }
  }

  clearValue() {
    this.onDelete();
    // this.updateMode = ChecktreeItemUpdateMode.ONLY_HERE;
    // this.value = '';
    // this.onChange({ value: this.value, updateMode: ChecktreeItemUpdateMode.ONLY_HERE });
  }
}
