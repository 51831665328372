





































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import DialogService from '@/app/shared/utils/dialog.service';

const UserStore = namespace('User');
const ChecklistsStore = namespace('Checklists');

@Component({
  components: { PageHeader },
})
export default class ChecktreePageHeader extends Vue {
  @Prop() checktreeTitle: string;
  @Prop() isAllExpanded: boolean;
  @Prop({ default: false, type: Boolean }) isAdmin: boolean;
  localIsAllSelected: boolean = false;

  @UserStore.Getter
  public isAuthenticated: boolean;

  @ChecklistsStore.Getter
  public isAllSelected: boolean;

  @ChecklistsStore.Getter
  public isAllLocked: boolean;

  @ChecklistsStore.Getter
  public shouldEnumerateChecktree: boolean;

  @UserStore.Getter
  skipConfirmationDialog: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog: (value: boolean) => void;

  @ChecklistsStore.Action
  public lockAllItems!: (shouldLock: boolean) => void;

  @ChecklistsStore.Action
  public selectAllItems!: (shouldSelect: boolean) => void;

  @ChecklistsStore.Action
  public enumerateChecktreeItems!: (shouldEnumerate: boolean) => Promise<any>;

  @ChecklistsStore.Action
  public toggleFoldItems!: (shouldFold: boolean) => Promise<any>;

  @Emit('resume')
  resume() {
    // emiting event
  }

  @Watch('isAllSelected')
  onIsAllSelectedChange(isAllSelected: boolean) {
    this.localIsAllSelected = isAllSelected;
  }

  @Watch('stepTitle')
  onStepTitleChange(stepTitle: string) {
    // Set the meta title directly, since we are fetching this value asynchronously.
    if (stepTitle) document.title = `${stepTitle} - Envigo`;
  }

  async created() {
    this.localIsAllSelected = this.isAllSelected;
  }

  toggleIsAllSelected(shouldSelect: boolean | null) {
    if (this.skipConfirmationDialog) {
      this.selectAllItems(Boolean(shouldSelect));
      return;
    }

    const confirmCallback = (context: { checkboxValue: boolean }) => {
      this.setSkipConfirmationDialog(context.checkboxValue);
      this.selectAllItems(Boolean(shouldSelect));
    };

    const cancelCallback = () => {
      // Reset the switch to the previous value.
      this.localIsAllSelected = !shouldSelect;
    };

    DialogService.presentDialog(
      // eslint-disable-next-line no-irregular-whitespace
      `Are you sure you want to ${shouldSelect ? 'select' : 'deselect'} all?`,
      'This action will immediately update the existing list of items in the following modules.',
      'Yes',
      'Cancel',
      confirmCallback,
      cancelCallback,
      'Do not show me this again',
    );
  }

  toggleAllItemsExpand() {
    this.$store.dispatch('Loader/show');
    this.toggleFoldItems(this.isAllExpanded);
    setTimeout(() => {
      this.$store.dispatch('Loader/hide');
    }, 1000);
  }

  async onEnumerateChange(event: boolean) {
    try {
      await this.enumerateChecktreeItems(event);
    } catch (error) {
      console.warn(error);
    }
  }
}
