




















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep, debounce } from 'lodash';
import { Tree } from 'he-tree-vue';
import { namespace } from 'vuex-class';
import TinyEditorModal from '@/app/shared/components/TinyEditorModal.vue';
import CommentPlaceholder, { CommentPlaceholderChangeEvent } from './CommentPlaceholder.vue';
import HelpSettings from './HelpSettings.vue';
import ColorSettings, { ColorSettingsChangeEvent } from './ColorSettings.vue';
import { UpdatedProperty } from '@/app/shared/store/modules/checklists.store-module';
import { TooltipOptions } from '@/app/shared/store/modules/ui.store-module';
import DialogService from '@/app/shared/utils/dialog.service';
import { User } from '@/app/shared/models/User';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { ChecktreeItemUpdateMode } from '@/app/shared/enums/checktree-item-update-mode.enum';

const ChecklistsStore = namespace('Checklists');
const UserStore = namespace('User');
const UiStore = namespace('UI');

@Component({
  components: { TinyEditorModal, CommentPlaceholder, HelpSettings, ColorSettings },
})
export default class CheckItem extends Vue {
  @Prop() node: CheckTreeItem;
  @Prop() path: number[];
  @Prop() tree: Tree;
  @Prop() index: number;
  @Prop({ default: false, type: Boolean }) isAdmin: boolean;

  @ChecklistsStore.Getter
  shouldEnumerateChecktree!: boolean;

  @UserStore.State
  user!: User;

  title: string = '';

  // admin actions
  isCommentPlaceholderVisible: boolean = false;
  isHelpSettingsVisible: boolean = false;
  isColorSettingsVisible: boolean = false;

  // user actions
  userComment: string = null;
  userCommentTinyEditorVisible: boolean = false;
  @Watch('userCommentTinyEditorVisible')
  onTinyEditorVisibleChange() {
    this.$store.dispatch('UI/setTinyEditorVisibility', this.userCommentTinyEditorVisible);
  }

  helpTooltipVisible: boolean = false;

  get isChecked() {
    return this.isAllChecked || this.isPartiallyChecked;
  }

  get isAllChecked() {
    return this.areAllChildrenChecked(this.node);
  }

  get isPartiallyChecked() {
    return !this.isAllChecked && this.areSomeChildrenChecked(this.node);
  }

  areAllChildrenChecked(node: CheckTreeItem): boolean {
    return node.checked && node.children && node.children.every((child) => this.areAllChildrenChecked(child));
  }

  areSomeChildrenChecked(node: CheckTreeItem): boolean {
    return node.checked || (node.children && node.children.some((child) => this.areSomeChildrenChecked(child)));
  }

  created() {
    //TODO: dev only
    if (this.node.id === 19) this.helpTooltipVisible = true;

    this.isColorSettingsVisible = this.node.color && this.node.color !== '';
    this.isCommentPlaceholderVisible = this.node.comment_placeholder && this.node.comment_placeholder !== '';
    this.isHelpSettingsVisible = this.node.help && this.node.help !== '';

    this.title = this.node.title;
    this.userComment = this.node.comment;

    if (this.node.checked !== this.node.$checked) {
      this.toggleCheck();
    }
  }

  @Watch('node', { deep: true })
  onNodeValueChange(val: CheckTreeItem, oldVal: CheckTreeItem) {
    this.isColorSettingsVisible = this.isColorSettingsVisible ? true : val.color && val.color !== '';
    this.isCommentPlaceholderVisible = this.isCommentPlaceholderVisible
      ? true
      : val.comment_placeholder && val.comment_placeholder !== '';
    this.isHelpSettingsVisible = this.isHelpSettingsVisible ? true : val.help && val.help !== '';

    if (val.checked !== oldVal.checked) {
      (this.tree as any).toggleCheck(this.node, this.path);
    }
  }

  get isItemLockedByOtherUser() {
    // return this.node.locked_by;
    return this.node.locked_by && this.node.locked_by.person.id !== this.user.id;
  }

  get itemOrder() {
    let value: string = (this.path[0] + 1).toString();
    for (let index = 1; index < this.path.length; index++) {
      const el = this.path[index];
      value = value + '.' + (el + 1);
    }
    return value;
  }

  get selectOptions() {
    return [
      { label: 'Apply only here', value: ChecktreeItemUpdateMode.ONLY_HERE },
      { label: 'Apply to container', value: ChecktreeItemUpdateMode.CONTAINER },
      { label: 'Apply to all', value: ChecktreeItemUpdateMode.ALL },
    ];
  }

  get isHelpIconVisible() {
    // return true;
    return !this.isAdmin && this.node.help;
  }

  @ChecklistsStore.Action
  public addChecklistItem!: (clickedItem: { node: CheckTreeItem; path: number[] }) => void;

  @ChecklistsStore.Action
  public deleteItem!: (payload: { uuid: string; path: number[] }) => void;

  @ChecklistsStore.Action
  private updateChecklistItem!: (payload: {
    updatedItem: CheckTreeItem;
    path: number[];
    updateMode: ChecktreeItemUpdateMode;
    propertyUpdated: UpdatedProperty;
  }) => void;

  @UiStore.Action
  public showTooltip!: (options: TooltipOptions) => void;

  toggleCheck() {
    (this.tree as any).toggleCheck(this.node, this.path);
  }

  toggleFold() {
    (this.tree as any).toggleFold(this.node, this.path);

    // For now, disable updating folded state on the server (#103).
    //   We only need local `$folded` state to render it client-side.
    //   More info here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/103
    // const updatedItem: CheckTreeItem = { ...this.node };
    // console.log(updatedItem.$folded);
    // this.updateChecklistItem({
    //   updatedItem,
    //   path: this.path,
    //   updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
    //   propertyUpdated: '$folded',
    // });
  }

  showHelpTooltip(event: PointerEvent) {
    const el: HTMLElement = event.target as HTMLElement;
    const elDOMRect: DOMRect = el.getBoundingClientRect();
    this.showTooltip({
      text: this.node.help,
      position: 'bottom',
      top: elDOMRect.y + 32,
      left: elDOMRect.x - 20,
    });
  }

  updateCheckValue() {
    const updatedItem: CheckTreeItem = cloneDeep(this.node);
    updatedItem.checked = !updatedItem.checked;
    this.updateChecklistItem({
      updatedItem,
      path: this.path,
      updateMode: ChecktreeItemUpdateMode.CONTAINER,
      propertyUpdated: 'checked',
    });
    this.toggleCheck();
  }

  onTitleInput = debounce((event: string) => {
    this.title = event;
    if (this.title) {
      const updatedItem: CheckTreeItem = { ...this.node };
      updatedItem.title = this.title;
      this.updateChecklistItem({
        updatedItem,
        path: this.path,
        updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
        propertyUpdated: 'title',
      });
    }
  }, 500);

  // onCommentPlaceholderChange(event: CommentPlaceholderChangeEvent) {
  //   if (!event.value) {
  //     this.isCommentPlaceholderVisible = false;
  //   }
  //   const updatedItem: CheckTreeItem = { ...this.node };
  //   updatedItem.comment_placeholder = event.value;
  //   this.updateChecklistItem({
  //     updatedItem,
  //     path: this.path,
  //     updateMode: event.updateMode,
  //     propertyUpdated: 'comment_placeholder',
  //   });
  // }

  onCommentPlaceholderChange = debounce((event: CommentPlaceholderChangeEvent) => {
    const updatedItem: CheckTreeItem = { ...this.node };
    updatedItem.comment_placeholder = event.value;
    this.updateChecklistItem({
      updatedItem,
      path: this.path,
      updateMode: event.updateMode,
      propertyUpdated: 'comment_placeholder',
    });
  }, 500);

  onCommentPlaceholderDelete() {
    const deleteClosure = () => {
      this.isCommentPlaceholderVisible = false;
      const updatedItem: CheckTreeItem = { ...this.node };
      updatedItem.comment_placeholder = '';
      this.updateChecklistItem({
        updatedItem,
        path: this.path,
        updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
        propertyUpdated: 'comment_placeholder',
      });
    };

    if (this.node.comment_placeholder && this.node.comment_placeholder.length > 0) {
      DialogService.presentDialog('Are you sure?', 'Lorem ipsum dolor sit amet.', 'Yes', 'Cancel', () => {
        deleteClosure();
      });
    } else {
      deleteClosure();
    }
  }

  // onHelpSettingsChange(event: HelpSettingsChangeEvent) {
  //   if (!event.value) {
  //     this.isHelpSettingsVisible = false;
  //   }
  //   const updatedItem: CheckTreeItem = { ...this.node };
  //   updatedItem.help = event.value;
  //   this.updateChecklistItem({
  //     updatedItem,
  //     path: this.path,
  //     updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
  //     propertyUpdated: 'help',
  //   });
  // }

  onHelpSettingsChange = debounce((event: CommentPlaceholderChangeEvent) => {
    const updatedItem: CheckTreeItem = { ...this.node };
    updatedItem.help = event.value;
    this.updateChecklistItem({
      updatedItem,
      path: this.path,
      updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
      propertyUpdated: 'help',
    });
  }, 500);

  onHelpSettingsDelete() {
    const deleteClosure = () => {
      this.isHelpSettingsVisible = false;
      const updatedItem: CheckTreeItem = { ...this.node };
      updatedItem.help = '';
      this.updateChecklistItem({
        updatedItem,
        path: this.path,
        updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
        propertyUpdated: 'help',
      });
    };

    // console.log(this.node.help);

    if (this.node.help && this.node.help.length > 0) {
      DialogService.presentDialog('Are you sure?', 'Lorem ipsum dolor sit amet.', 'Yes', 'Cancel', () => {
        deleteClosure();
      });
    } else {
      deleteClosure();
    }
  }

  onColorSettingsChange(event: ColorSettingsChangeEvent) {
    const update = (value: string) => {
      const updatedItem: CheckTreeItem = { ...this.node };
      updatedItem.color = value || '';
      this.updateChecklistItem({
        updatedItem,
        path: this.path,
        updateMode: value ? event.updateMode : ChecktreeItemUpdateMode.ONLY_HERE,
        propertyUpdated: 'color',
      });
    };

    if (!event) {
      // delete action
      if (this.node.color && this.node.color.length > 0) {
        DialogService.presentDialog('Are you sure?', 'Lorem ipsum dolor sit amet.', 'Yes', 'Cancel', () => {
          update(null);
          this.isColorSettingsVisible = false;
        });
      } else {
        update(null);
        this.isColorSettingsVisible = false;
      }
    } else {
      // update action
      update(event.value);
    }

    // if (!event.value) {
    //   this.isColorSettingsVisible = false;
    // } else {
    // }
  }

  toggleUserComment() {
    const updatedItem: CheckTreeItem = { ...this.node };
    updatedItem.comment_visible = !updatedItem.comment_visible;
    this.updateChecklistItem({
      updatedItem,
      path: this.path,
      updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
      propertyUpdated: 'comment_visible',
    });
  }

  onUserCommentInput = debounce((event: string) => {
    this.userComment = event;
    // console.log(this.userComment);
    const updatedItem: CheckTreeItem = { ...this.node };
    updatedItem.comment = this.userComment;
    this.updateChecklistItem({
      updatedItem,
      path: this.path,
      updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
      propertyUpdated: 'comment',
    });
  }, 500);

  toggleLock() {
    const updatedItem: CheckTreeItem = { ...this.node };
    updatedItem.lock = !updatedItem.lock;
    this.updateChecklistItem({
      updatedItem,
      path: this.path,
      updateMode: ChecktreeItemUpdateMode.ONLY_HERE,
      propertyUpdated: 'lock',
    });
  }

  showDeleteDialog() {
    DialogService.presentDialog('Are you sure?', 'Lorem ipsum dolor sit amet.', 'Yes', 'Cancel', () => {
      this.isCommentPlaceholderVisible = false;
      this.isHelpSettingsVisible = false;
      this.isColorSettingsVisible = false;
      this.deleteItem({ uuid: this.node.uuid, path: this.path });
    });
  }
}
