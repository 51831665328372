

























import TinyEditorModal from '@/app/shared/components/TinyEditorModal.vue';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

export interface HelpSettingsChangeEvent {
  value: string;
}

@Component({
  components: { TinyEditorModal },
})
export default class HelpSettings extends Vue {
  @Prop() initialValue!: string;

  value: string = null;
  tinyEditorVisible: boolean = false;
  @Watch('tinyEditorVisible')
  onTinyEditorVisibleChange() {
    this.$store.dispatch('UI/setTinyEditorVisibility', this.tinyEditorVisible);
  }

  initialized: boolean = false;

  created() {
    this.value = this.initialValue;
  }

  @Emit('change')
  onChange(_event: HelpSettingsChangeEvent) {
    // just emiting value
  }

  @Emit('delete')
  onDelete() {
    // just emiting value
  }

  @Watch('initialValue')
  onInitialValueChange() {
    this.value = this.initialValue;
  }

  @Watch('value')
  onValueChange(newVal: string, oldVal: string) {
    if (this.initialValue && !oldVal) {
      return;
    }
    if (this.value === '' || (this.value && this.value.trim().length > 0)) {
      this.onChange({ value: this.value });
    }
  }

  clearValue() {
    this.onDelete();
    // this.value = '';
    // this.onChange({ value: this.value });
  }
}
